import { funnel } from './icons'
import { useState } from 'react'
import { Input } from 'reactstrap'
import {
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
} from '@mui/material'

export function GenericTableHeader({ data, set_sort, filter_by }) {
    // const filterSetter = (filter) => {
    //     filter_by(filter)
    // }

    const getColumns = () => {
        let columns = []
        for ( const column in data.meta ) {
            // let filterable = ""
            // if (data.meta[column].filterable) {
            //     filterable = <FilterSetter filterSetter={filterSetter} current_filter={data.options.filterBy} column={column} />
            // }


            if (data.meta[column].sortable) {
                let label = data.meta[column].label
                let sortClass = "isSortable"
                let order = ""

                if ( data.options.sortBy === column ) {
                    order = "asc"
                }

                if ( data.options.sortBy === "-" + column ) {
                    order = "desc"
                }

                columns.push(
                    <TableCell 
                        key={label} 
                        align={(data.meta[column].type == 'integer' || data.meta[column].type == 'float') ? 'right' : 'left' }
                        sortDirection={order}    
                    >
                        <TableSortLabel
                            active={(data.options.sortBy === column || data.options.sortBy === "-" + column)}
                            direction={order}
                            onClick={() => set_sort(column)}
                        >
                            <b>{label}</b>
                        </TableSortLabel>
                    </TableCell>
                )
            } else {
                columns.push(
                    <TableCell>
                        <b>{data.meta[column].label}</b>
                    </TableCell>)
            }
        }
        return columns
    }

    return (
        <TableHead>
            <TableRow key="thisistheheaderitismighty">
                { getColumns() }
            </TableRow>
        </TableHead>
    )
}

// function FilterSetter({filterSetter, current_filter, column}) {
//     const [showFilter, setShowFilter] = useState(false)
//     const [inputValue, setInputValue] = useState(current_filter.name === column ? current_filter.value : "" )

//     const handleChange = (e) => {
//         setInputValue(e.target.value)
//     }
    
//     const handleFocus = (e) => {
//         e.target.select()
//     }

//     const checkKeyPress = (e) => {
//         const { key, keyCode } = e;
//         if (keyCode === 13) {
//             setShowFilter(false)
//             filterSetter({ "name": column, "value": inputValue })
//         }
//     }

//     let input = <></>

//     const setFilter = () => {
//         setShowFilter(!showFilter)
//     }

//     if (showFilter) {
//         input = (
//             <div>
//                 <Input 
//                     type="text"
//                     onChange={handleChange}
//                     value={inputValue} 
//                     autoFocus={true} 
//                     onFocus={handleFocus}
//                     onKeyDown={checkKeyPress}
//                 />
//             </div>
//         )
//     }

//     const icon = showFilter ? "" : <span style={{ "padding-left": "0.5em", "cursor":"pointer"}} onClick={setFilter}>{funnel}</span>

//     return (
//         <>
//             {input}
//             <span>
//                 { ( (inputValue !== "" && column === current_filter.name ) ? " = " + inputValue : "")}
//             </span>
//             {icon}
//         </>
//     )
// }