import profile from './ken_profile.png'
import './About.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export const About = () => {
    return (
        <>
        <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Grid container spacing={2} xs={{ mx:"10"}}>
                <Grid xs={12} md={4}>
                    <div className="aboutPic">
                        <img src={profile} alt="Ken Washburn"/>
                    </div>
                </Grid>
                <Grid xs={12} md={6}>
                    <Typography variant="body1" >
                        <h4>About the creator of this site, Ken Washburn</h4>
                    </Typography>
                    <Typography variant="body1" align='left' >
                        <p>
                            <Link target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/ken-washburn-fredericton/">Ken's LinkedIn profile</Link>
                        </p>
                        <p>
                            First and foremost, thanks to <Link target="_blank" rel="noreferrer" href="https://kijiji.ca">Kijiji</Link> for granting permission to pull data from their listing.
                        </p>
                        <p>
                            Ken is a Computer Scientist who has been working professionally since 1988. He's
                            had many roles in his career including Support, Ops, Dev/ops, Analyst, Developer and
                            Team Lead. He is truly passionate about technology, but is keenly interested in
                            web technologies, hence this site.
                        </p>
                        <p>
                            This site serves two purposes. First is to showcase Ken's technical capabilities,
                            and second is to reveal data about rental rates for properties in Canada, Atlantic Canada initally.
                            There is much talk about the increase in housing, and how much housing costs. It is 
                            obviously a much bigger topic then just rental rates, but this does give some insite
                            into how much you can expect to pay given a certain locality.
                        </p>
                        <h4>About the site</h4>
                        <p>
                            The site is composed of Django with the Rest Framework for the API, and React as a Single Page Application (SPA).
                            It uses Redis for caching and background process management. Data is handled by Postgres and it uses Ngnix for
                            static content and a reverse proxy to the API. These elements are all using Docker images and deployed with
                            Kubernetes on Digital Ocean.
                        </p>
                        <p>
                            There is still much work and tweaking to be done. Goals for this project:
                        </p>
                        <ul>
                            <li>Incorporate SSO with major sites, Google to start</li>
                            <li>Solicit input from visitors of the site about rental rates in their location</li>
                            <li>Add filters for Unit Type, i.e. 1 bedroom, 2 bedroom, bachelor, etc.</li>
                            <li>Add postal code lookup, shows location on the map</li>
                            <li>Add ability to add new postal code based on current map lat/log</li>
                        </ul>
                        <p>
                            Join the discord for this project here. <Link target="_blank" rel="noreferrer" href="https://discord.gg/jZjEr92t">Discord here</Link>
                        </p>
                        <h4>Open source acknowledgements</h4>
                        <p>
                            Like most Open Source Projects, the projects mentioned below have many more Open Source dependancies. 
                            You can visit each project for more information on their dependancies.
                        </p>
                        <p>
                            Geonames.org - <Link target="_blank" rel="noreferrer" href="http://www.geonames.org/">http://www.geonames.org/</Link><br />
                            Django - <Link target="_blank" rel="noreferrer" href="https://www.djangoproject.com/">https://www.djangoproject.com/</Link><br />
                            psycopg - <Link target="_blank" rel="noreferrer" href="https://psycopg.org/">https://psycopg.org/</Link><br />
                            djangorestframework - <Link target="_blank" rel="noreferrer" href="https://www.django-rest-framework.org/">https://www.django-rest-framework.org/</Link><br />
                            markdown - <Link target="_blank" rel="noreferrer" href="https://python-markdown.github.io/">https://python-markdown.github.io/</Link><br />
                            django-filter - <Link target="_blank" rel="noreferrer" href="https://github.com/carltongibson/django-filter/tree/main">https://github.com/carltongibson/django-filter/tree/main</Link><br />
                            django-cors-headers - <Link target="_blank" rel="noreferrer" href="https://pypi.org/project/django-cors-headers/">https://pypi.org/project/django-cors-headers/</Link><br />
                            requests - <Link target="_blank" rel="noreferrer" href="https://requests.readthedocs.io/">https://requests.readthedocs.io/</Link><br />
                            beautifulsoup4 - <Link target="_blank" rel="noreferrer" href="https://www.crummy.com/software/BeautifulSoup/bs4/">https://www.crummy.com/software/BeautifulSoup/bs4/</Link><br />
                            django-q - <Link target="_blank" rel="noreferrer" href="https://django-q2.readthedocs.org/">https://django-q2.readthedocs.org/</Link><br />
                            django-redis - <Link target="_blank" rel="noreferrer" href="https://github.com/redis/redis-py">https://github.com/redis/redis-py</Link><br />
                            psutil - <Link target="_blank" rel="noreferrer" href="https://github.com/giampaolo/psutil">https://github.com/giampaolo/psutil</Link><br />
                            python-memcached - <Link target="_blank" rel="noreferrer" href="https://github.com/linsomniac/python-memcached">https://github.com/linsomniac/python-memcached</Link><br />
                            gunicorn - <Link target="_blank" rel="noreferrer" href="https://gunicorn.org/">https://gunicorn.org/</Link><br />
                            Tanstack react-query - <Link target="_blank" rel="noreferrer" href="https://tanstack.com/query/latest">https://tanstack.com/query/latest</Link><br />
                            React - <Link target="_blank" rel="noreferrer" href="https://react.dev/">https://react.dev/</Link><br />
                            Axios - <Link target="_blank" rel="noreferrer" href="https://axios-http.com/">https://axios-http.com/</Link><br />
                            Bootstrap - <Link target="_blank" rel="noreferrer" href="https://getbootstrap.com/">https://getbootstrap.com/</Link><br />
                            Bootstrap-icons -<Link target="_blank" rel="noreferrer" href="https://icons.getbootstrap.com/">https://icons.getbootstrap.com/</Link><br />
                            Reactstrap - <Link target="_blank" rel="noreferrer" href="https://github.com/reactstrap/reactstrap">https://github.com/reactstrap/reactstrap</Link><br />
                            dotenv - <Link target="_blank" rel="noreferrer" href="https://github.com/motdotla/dotenv#readme">https://github.com/motdotla/dotenv#readme</Link><br />
                            hash-it - <Link target="_blank" rel="noreferrer" href="https://www.npmjs.com/package/hash-it">https://www.npmjs.com/package/hash-it</Link><br />
                            leaflet - <Link target="_blank" rel="noreferrer" href="https://leafletjs.com/">https://leafletjs.com/</Link><br />
                            react-leaflet - <Link target="_blank" rel="noreferrer" href="https://react-leaflet.js.org/">https://react-leaflet.js.org/</Link><br />
                            Geonames - <Link target="_blank" rel="noreferrer" href="http://www.geonames.org/">http://www.geonames.org/</Link>
                        </p>
                     </Typography>
                </Grid>
                <Grid md={2}>
                </Grid>
            </Grid>
        </Box>
        </>
    )
}