import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { GeoMap } from './components/GeoMap';
import { 
  Metrics, 
  loader as metricloader 
} from './components/Metrics';
import { About } from './components/about/About';
import { Home } from './components/Home';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    id: "app",
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: "map",
        element: <GeoMap />
      },
      {
        path: "metrics/:dataset",
        element: <Metrics />,
        loader: metricloader
      },
      {
        path: "about",
        element: <About />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
