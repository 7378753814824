import './components/RentStats.css'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NavLink, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { GoogleAd } from './components/ads/GoogleAd';
//
// MUI stuff
//
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
//
// End MUI stuff
//

const queryClient = new QueryClient()
// console.log("Using app version: " + process.env.REACT_APP_VERSION)

function App() {
  const [shareOpen, setShareOpen] = useState(false)
  const path = useLocation().pathname.split("/")[1]

  const handleShareOpen = () => {
    setShareOpen(true)
  }

  const handleShareClose = () => {
    setShareOpen(false)
  }

  const shareUrl = "https://rentalstats.info"

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <AppBar position="static">
          <Container maxWidth="false">
          <Toolbar disableGutters>
          <NavLink className="menuNav" to={'/'}>
            <Typography
              variant="h4"
              noWrap
              sx={{
                mr: 2,
                display: { md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'white',
                textDecoration: 'none',
              }}
            >
                      RSiC
            </Typography>
          </NavLink>
          <Grid container>
                <Grid xs="11">
                  <Box justifyContent="flex-start" display="flex" container sx={{ flexGrow: 1 }}>
                      <Button key='map' sx={{ my: 2, color: 'white', display: 'block', minWidth: "40px" }} >
                        <NavLink className="menuNav" to={'/map'}>
                          <Typography sx={{color: 'white'}}>
                            Map
                          </Typography>
                        </NavLink>
                      </Button>
                      <Button key='data' sx={{ my: 2, color: 'white', display: 'block', minWidth: "40px" }} >
                        <NavLink className="menuNav" to={'/metrics/municipalities'}>
                          <Typography sx={{color: 'white'}}>
                            Data
                          </Typography>
                        </NavLink>
                      </Button>
                      <Button key='about' sx={{ my: 2, color: 'white', display: 'block', minWidth: "40px" }} >
                        <NavLink className="menuNav" to={'/about'}>
                          <Typography sx={{color: 'white'}}>
                            About
                          </Typography>
                        </NavLink>
                      </Button>
                  </Box>
              </Grid>
              <Grid xs="1">
                <Box justifyContent="flex-end" display="flex" container sx={{ flexGrow: 1, minWidth: "40px" }}>
                  <Button key='about' sx={{ my: 2, color: 'white', display: 'block' }} onClick={handleShareOpen} >
                        <ShareIcon />
                  </Button>
                </Box>
            </Grid>
        </Grid>
        </Toolbar>
        </Container>
        </AppBar>
        <GoogleAd />
        <br />
        <Outlet />
        <Dialog onClose={handleShareClose} open={shareOpen}>
              <DialogTitle sx={{ borderBottom: "1px solid darkgrey"}}>Share the link for Rental Statistics in Canada</DialogTitle>
                <Box sx={{ m: "10px"}}>
                  <Box sx={{ p: "3px", my: '3px', display: "inline" }}>
                    <EmailShareButton url={shareUrl} subject="Rental Statistics in Canada">
                      <EmailIcon round={true} />
                    </EmailShareButton>
                  </Box>
                  <Box sx={{ p: "3px", my: '3px', display: "inline" }}>
                    <FacebookShareButton url={shareUrl} hashtag="#rentstats">
                      <FacebookIcon round={true} />
                    </FacebookShareButton>
                  </Box>
                  <Box sx={{ p: "3px", my: '3px', display: "inline" }}>
                    <FacebookMessengerShareButton url={shareUrl}><FacebookMessengerIcon round={true} /></FacebookMessengerShareButton>
                  </Box>
                  <Box sx={{ p: "3px", my: '3px', display: "inline" }}>
                    <LinkedinShareButton url={shareUrl} title="Rental Statistics in Canada" source="https://rentalstats.info">
                      <LinkedinIcon round={true} />
                    </LinkedinShareButton>
                  </Box>
                  <Box sx={{ p: "3px", my: '3px', display: "inline" }}>
                    <RedditShareButton url={shareUrl} title="Rental Statistics in Canada">
                      <RedditIcon round={true} />
                    </RedditShareButton>
                  </Box>
                  <Box sx={{ p: "3px", my: '3px', display: "inline" }}>
                    <TwitterShareButton 
                      title="Rental Statistics in Canada"
                      hashtags={["rentstats"]}
                      url={shareUrl}>
                      <XIcon round={true} />
                    </TwitterShareButton>
                  </Box>
                  <Box sx={{ p: "3px", my: '3px', display: "inline" }}>
                    <WhatsappShareButton url={shareUrl} title="Rental Statistics in Canada">
                      <WhatsappIcon round={true} />
                    </WhatsappShareButton>
                  </Box>
                </Box>
        </Dialog>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  )
}

export default App;
