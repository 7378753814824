import { 
    Drawer,
    Box,
    Typography,
    Button,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material"
import { useQuery } from '@tanstack/react-query';
import { getData } from "./generic/GenericTableHelper";
import { useState } from "react";

export function Filter({open, toggle, meta, set_filter, filter_value}) {

    let apiUrl = "/api/"

    if ("url" in meta[0]) {
        apiUrl = meta[0]["url"]
    }

    const setFilterOption = (event) => {

        let name = ""
        for (let i in filterOptions) {
            if ( filterOptions[i]["id"] === event.target.value) {
                name = filterOptions[i]["type"]
            }
        }
        set_filter({"filterBy":{"name":"unit_type_id","value":event.target.value},"name":name})
    }

    let filterOptions = []
    const dataResults = useQuery({queryKey: ['getFilterOptions', { apiUrl }], queryFn: getData})
    if (dataResults.isFetched && dataResults.data)  {
        filterOptions = dataResults.data.results
    }
    
    function getFilters(filterOptions) {

        const unitType = filterOptions.map( (item) => {
            return (
                <MenuItem value={item["id"]}>{item["type"]} {"("}{item["count"]}{")"}</MenuItem>
            )
        })

        return (
            <Box sx={{ minWidth:'200px'}}>
                <Paper elevation="3">
                    <FormControl fullWidth>
                        <InputLabel>
                            {meta[0].label}
                        </InputLabel>
                        <Select
                            value={filter_value}
                            label={meta[0].label}
                            onChange={setFilterOption}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {unitType}
                        </Select>
                    </FormControl>
                </Paper>
            </Box>
        )
    }

    return (
        <Drawer
            anchor="right"
            open={open}
            variant="persistant"
        >
            <Box sx={{ p: "20px", mt:"20px"}}>
                Filter by:<br /><br />
                {getFilters(filterOptions)}
                <br />
            </Box>
            <Button onClick={() => toggle(false)}>
                Close
            </Button>
        </Drawer>
    )
}