import { useState } from 'react';
import { getMetaData, getData } from './GenericTableHelper'
import { useQuery } from '@tanstack/react-query';
import { GenericTable } from './GenericTable';
import { Filter } from "../Filter";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { 
    Button, 
    Box, 
    Drawer,
    Typography,
    Grid,
    Stack,
    Chip,
} from "@mui/material";


export default function Table({url, meta}) {
    const [options, setOptions] = useState({ "page": "1", "pageSize": "10", "sortBy": "-avg", "filterBy": { "name": "", "value": "" } });
    const [currentUrl, setCurrentUrl] = useState(url)

    const [filterOpen, setFilterOpen] = useState(false)
    const [filterName, setFilterName] = useState("")

    const toggleFilter = (openclose) => {
        setFilterOpen(openclose)
    }

    const filterMeta = [{
        name: "unit_type",
        label: "Unit Type",
        type: "dropdown",
        url: window.location.protocol + "//" + window.location.host + "/api/unit_type/"
    }]

    const setFilter = (filter) => {
        let newOptions = {...options}
        newOptions["filterBy"] = filter.filterBy
        setOptions(newOptions)
        setFilterName(filter.name)
        // console.log(options)
    }

    const clearFilter = () => {
        let newOptions = {...options}
        newOptions["filterBy"] =  { "name": "", "value": "" }
        setOptions(newOptions)
        setFilterName("")
    }

    if (url !== currentUrl) {
        let newOptions = { ...options }
        newOptions.page = 1
        setOptions(newOptions)
        setCurrentUrl(url)
    }

    const apiUrl = url
    let table = { "meta": {}, "data": {}, "pages": null, "page": 1, "pageSize": "10", "count": 0, "fetched": false, options: options }

    const metaResults = useQuery({queryKey: ['getMetadata', { apiUrl }], queryFn: getMetaData})


    if (meta) {
        table.meta = meta
    } else {
        if (metaResults.isFetched) {
            table.meta = metaResults.data.actions.POST
        }
    }

    const dataResults = useQuery({queryKey: ['getTableData', { apiUrl, options }], queryFn: getData})

    if (dataResults.isFetched)  {
        table.data = dataResults.data.results
        table.pages = dataResults.data.pages
        table.count = dataResults.data.count
        table.pageSize = options["pageSize"]
        table.page = options["page"] - 1
        table.fetched = true
    }

    const set_page = (pageNum) => {
        let newOptions = { ...options }

        // if (pageNum < 0) {
        //     newOptions.page = 0
        // } else if (table.pages && pageNum > table.pages ) {
        //     newOptions.page = table.pages - 1
        // } else {
            // newOptions.page = pageNum
        // }

        newOptions.page = pageNum + 1
        setOptions(newOptions)
    }

    const set_page_size = (pageSize) => {
        let newOptions = { ...options }
        newOptions.pageSize = String(pageSize)
        newOptions.page = 1
        setOptions(newOptions)
    }

    const set_sort = (sortOrder) => {
        let newOptions = { ...options }

        if ( "-" + sortOrder === options.sortBy) {
            newOptions.sortBy = ""
        } else if ( options.sortBy === sortOrder) {
            newOptions.sortBy = "-" + sortOrder
        } else {
            newOptions.sortBy = sortOrder
        }

        setOptions(newOptions)
    }

    const filter_by = (filter) => {
        let newOptions = { ...options }

        newOptions.filterBy = filter

        if ( filter.value === "") {
            newOptions.filterBy.name = ""
        }
        
        setOptions(newOptions)
    }

    return (
        <>
        <Box sx={{ width: "95%", mx: "auto"}}>
            <Grid container>
                <Grid xs="12">
                    <Box justifyContent="flex-end" display="flex">
                        <Stack direction="row">
                            { filterName !== "" ? <Chip label={filterName} onDelete={() => clearFilter()} /> : ""}
                            <Button variant="text" onClick={() => toggleFilter(true)}>
                                <FilterAltIcon />
                            </Button>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ overflowX: "auto"}}>
                <GenericTable data={table} set_page={set_page} set_page_size={set_page_size} set_sort={set_sort} filter_by={filter_by} />
            </Box>
            <Filter open={filterOpen} toggle={toggleFilter} meta={filterMeta} set_filter={setFilter} filter_value={options.filterBy.value} />
        </Box>
        </>

    )
}
