import { GenericTableHeader } from "./GenericTableHeader"
import { GenericTableData } from './GenericTableData';
import { GenericTableFooter } from './GenericTableFooter';
import {
    TableContainer,
    Table,
    Box,
    Paper,
} from '@mui/material'

export function GenericTable({ data, set_sort, set_page, set_page_size, filter_by }) {
    return(
        <>
        <Box alignSelf={"center"} sx={{ minWidth:'fit-content', mx: 'auto' , xs: { px: "15%"} }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table size="small">
                        <GenericTableHeader data={data} set_sort={set_sort} filter_by={filter_by} />
                        <GenericTableData data={data} />
                    </Table>
                </TableContainer>
                <GenericTableFooter data={data} set_page={set_page} set_page_size={set_page_size} />
            </Paper>
        </Box>
        </>
    )
}
