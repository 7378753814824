import "./GeoMap.css"
import { 
    MapContainer, 
    TileLayer, 
    Popup, 
    FeatureGroup, 
    Circle, 
} from 'react-leaflet'
import { useMapEvents, useMap } from 'react-leaflet/hooks'
import { getData } from './generic/GenericTableHelper'
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { 
    Button, 
    Box, 
    Drawer,
    Typography,
    Grid,
    Stack,
    Chip,
} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Filter } from "./Filter";

// const fredericton = [45.9636, -66.6431]

export const GeoMap = ({dataset}) => {
    const [options, setOptions] = useState({ "page": "1", "pageSize": "1000", "sortBy": "", "filterBy": { "name": "", "value": "" } })
    const [zoom, setZoom] = useState(4.2)
    const [zooming, setZooming] = useState(false)
    const [bounds,setBounds] = useState({'_southWest': {'lat': 0, 'lng': 0}, '_northEast': {'lat': 0.1, 'lng': 0.1}})
    const [heatScaleOpen, setHeatScaleOpen] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [filterName, setFilterName] = useState("")

    const toggleFilter = (openclose) => {
        setFilterOpen(openclose)
    }

    const filterMeta = [{
        name: "unit_type",
        label: "Unit Type",
        type: "dropdown",
        url: window.location.protocol + "//" + window.location.host + "/api/unit_type/"
    }]

    const setFilter = (filter) => {
        let newOptions = {...options}
        newOptions["filterBy"] = filter.filterBy
        setOptions(newOptions)
        setFilterName(filter.name)
        // console.log(options)
    }

    const clearFilter = () => {
        let newOptions = {...options}
        newOptions["filterBy"] =  { "name": "", "value": "" }
        setOptions(newOptions)
        setFilterName("")
    }

    let table = { "meta": {}, "data": [], columns:[], "pages": null, "fetched": false, options: options }

    const heat_colors = [
        "#a50026",
        "#d73027",
        "#f46d43",
        "#fdae61",
        "#fee090",
        "#ffffbf",
        "#e0f3f8",
        "#abd9e9",
        "#74add1", 
        "#4575b4",
        "#313695"
    ]

    const datasets = {
        "postalcode": {
            "url": window.location.protocol + "//" + window.location.host + "/api/postal_code_metrics/?",
            "meta": null
        },
        "municipality": {
            "url": window.location.protocol + "//" + window.location.host + "/api/municipality_code_metrics/?",
            "meta": null
        } 
    }

    const zoomDataset = zoom > 10 ? "postalcode" : "municipality"
    const apiUrl = datasets[zoomDataset].url

    const dataResults = useQuery({queryKey: ['getTableData', { apiUrl, options, bounds }], queryFn: getData})
    if (dataResults.isFetched && dataResults.data)  {
        table.data = dataResults.data.results
        table.fetched = true
    }

    const onLoad = (map) => {
        if (map) {
            setBounds(map.target.getBounds())
        }
    }

    function toggleHeatScale(openclose) {
        setHeatScaleOpen(openclose)
    }

    function getHeatScale() {
        const scale = heat_colors.map( (item, i) => {
            let range = ((10 - i) * 400) + " - " + (((11 - i) * 400) - 1)
            if ( i === 0 ) {
                range = "4000 +"
            }
            if ( i === 10 ) {
                range = "399 - 0"
            }
            return (
                    <Box sx={{ p: "0px"}}>
                        <div style={{
                            "width":"120px",
                            "float":"left", 
                            "position":"relative", 
                            "backgroundColor":item, 
                            "border":"2px black", 
                            "paddingTop": "15px",
                            "paddingBottom": "15px",
                        }}>
                            <Typography align="center" color="black">
                                {range}
                            </Typography>
                        </div>
                    </Box>
            )
        })

        return (
            <Box sx={{ p: "5px", width: "130px", mt:"50px"}}>
                <Typography align="center" fontSize={"smaller"} sx={{ pb: "5px"}}>Average Price ($)</Typography>
                {scale}
            </Box>
        )
    }
    
    const getColor = (value) => {
        const index = (value > 3000) ? 0 : Math.ceil((4000 - value) / 400)
        return heat_colors[index]
    }

    const markers = table.data.map( (item) => {
        let zoomscale = 100
        let label = ""

        if ( zoomDataset === "municipality") {
            zoomscale =  Math.pow((16 - zoom), 4) * 1
            label = <div>{item.name}</div>
        } else {
            zoomscale = (zoom > 13) ? (75) :  Math.pow((16 - zoom), 1.4) * 50
            label = <div>
                {item.municipality_name}<br />
                {item.postal_code}
            </div>
        }

        if ( ! item.latitude) {
            return (
                <div></div>
            )
        }
        if (zooming) {
            return <></>
        } else {
            return (
                <FeatureGroup>
                    <Popup>
                        {label}
                        Average: {item.avg}<br />
                        Sample size: {item.count}
                    </Popup>
                    <Circle 
                        center={[item.latitude, item.longitude]} 
                        color={"grey"}
                        fillColor={getColor(item.avg)} 
                        fillOpacity={0.75}
                        radius={zoomscale} />
                </FeatureGroup>
            )
        }
    } )


    function Zoom() {
        const map = useMapEvents({
            zoomstart: () => {
                setZooming(true)
            },
            zoomend: () => {
                setZooming(false)
                setZoom(map.getZoom())
                setBounds(map.getBounds())
            },
            moveend: () => {
                setBounds(map.getBounds())
            },
            loaded: () => {
                setBounds(map.getBounds())
            }
        })

        return null
    }



    return(
        <>
        <Box sx={{ width: "95%", mx: "auto"}}>
            <Grid container>
                <Grid xs="6">
                    <Box justifyContent="flex-start" display="flex">
                        <Button variant="text" onClick={() => toggleHeatScale(true)}>
                            Show Scale
                        </Button>
                    </Box>
                </Grid>
                <Grid xs="6">
                    <Box justifyContent="flex-end" display="flex">
                        <Stack direction="row">
                            { filterName !== "" ? <Chip label={filterName} onDelete={() => clearFilter()} /> : ""}
                            <Button variant="text" onClick={() => toggleFilter(true)}>
                                <FilterAltIcon />
                            </Button>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <div style={{"alignItems":"center", "display":"flex", "justifyContent":"center"}}>
                <div style={{"height":"80vh", "width":"100%","alignItems":"center", "display":"flex", "justifyContent":"center"}}>
                    <MapContainer 
                        center={[60.2748, -94.7545]} 
                        zoom={zoom}
                        scrollWheelZoom={true} 
                        style={{"height":"100%","width":"100%"}}
                        whenReady={onLoad}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markers}
                        <Zoom />
                    </MapContainer>
                </div>
            </div>
        </Box>
        <Drawer
            anchor="left"
            open={heatScaleOpen}
            onClose={() => toggleHeatScale(false)}
        >
            {getHeatScale()}
        </Drawer>
        <Filter open={filterOpen} toggle={toggleFilter} meta={filterMeta} set_filter={setFilter} filter_value={options.filterBy.value} />
        </>
    )
}