import { useLoaderData } from 'react-router-dom';
import Table from './generic/Table';

export function loader( { params } ) {
    return { params }
}

export function Metrics() {
    const datasets = {
        "postalcode": {
            "url": window.location.protocol + "//" + window.location.host + "/api/postal_code_metrics/?",
            "meta": {
                "postal_code": {
                    "type": "string",
                    "required": false,
                    "read_only": false,
                    "label": "Postal code",
                    "max_length": 20,
                    "sortable": false,
                    "filterable": false
                },
                "municipality_name": {
                    "type": "string",
                    "required": true,
                    "read_only": false,
                    "label": "Municipality name",
                    "sortable": true,
                    "filterable": false
                },
                "province_name": {
                    "type": "string",
                    "required": true,
                    "read_only": false,
                    "label": "Province name",
                    "sortable": true,
                    "filterable": false
                },
                "avg": {
                    "type": "integer",
                    "required": true,
                    "read_only": false,
                    "label": "Avg",
                    "sortable": true,
                    "filterable": false
                },
                "min": {
                    "type": "integer",
                    "required": true,
                    "read_only": false,
                    "label": "Min",
                    "sortable": true,
                    "filterable": false
                },
                "max": {
                    "type": "integer",
                    "required": true,
                    "read_only": false,
                    "label": "Max",
                    "sortable": true,
                    "filterable": false
                },
                "count": {
                    "type": "integer",
                    "required": true,
                    "read_only": false,
                    "label": "Count",
                    "sortable": true,
                    "filterable": false
                },
            }
        },
        "municipality": {
            "url": window.location.protocol + "//" + window.location.host + "/api/municipality_code_metrics/?",
            "meta": {
                "name": {
                    "type": "string",
                    "required": true,
                    "read_only": false,
                    "label": "Name",
                    "max_length": 200,
                    "sortable": true,
                    "filterable": false
                },
                "province_name": {
                    "type": "string",
                    "required": true,
                    "read_only": false,
                    "label": "Province name",
                    "sortable": true,
                    "filterable": false
                },
                "avg": {
                    "type": "integer",
                    "required": true,
                    "read_only": false,
                    "label": "Avg",
                    "sortable": true,
                    "filterable": false
                },
                "min": {
                    "type": "integer",
                    "required": true,
                    "read_only": false,
                    "label": "Min",
                    "sortable": true,
                    "filterable": false
                },
                "max": {
                    "type": "integer",
                    "required": true,
                    "read_only": false,
                    "label": "Max",
                    "sortable": true,
                    "filterable": false
                },
                "count": {
                    "type": "integer",
                    "required": true,
                    "read_only": false,
                    "label": "Count",
                    "sortable": true,
                    "filterable": false
                }
            }
        } 
    }

    let dataset = useLoaderData().params.dataset
    dataset = Object.keys(datasets).indexOf(dataset) === -1 ? "municipality" : dataset 

    return (
        <>
            <Table url={datasets[dataset].url} meta={datasets[dataset].meta}  />
        </>
    )
}