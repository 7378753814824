import hash from 'hash-it';
import './generic.css';
import { Tooltip } from 'reactstrap'
import { useState } from 'react'
import {
    TableCell,
    TableRow,
    TableBody
} from '@mui/material'

export function GenericTableData({ data }) {

    const getRows = () => {
        if ( ! data.fetched ) {
            return []
        }
        let rows = []

        const metadata = data.meta
        const tabledata = data.data

        for ( const row of tabledata ) {
            let rowdata = []
            let hashseed = ""

            let rowNum = 0
            for ( const column in metadata ) {
                const idHash = "id" + String(hash(rowNum + row[column]))
                // const needsTrim = String(row[column]).length > 40

                // <div id={idHash}>{tooltip}</div>
                // let trimmed = <div>{String(row[column])}</div>
                // if ( needsTrim ) {
                //     trimmed = ( 
                //         <div>
                //             <span>{String(row[column]).substring(0,40)}</span>
                //             <TooltipItem item={String(row[column])} id={idHash} />
                //         </div> 
                //     )
                // }

                // const trimmed = String(row[column]).length > 40 ? String(row[column]).substring(0,40) + "..." : String(row[column])
                rowdata.push(
                    <TableCell
                        align={(metadata[column].type == 'integer' || metadata[column].type == 'float') ? 'right' : 'left' }
                    >
                        {String(row[column])}
                    </TableCell>
                )
                hashseed += row[column]
            }

            rows.push(<TableRow key={hash(hashseed)}>{rowdata}</TableRow>)
        }

        return rows
    }


    return (
        <TableBody>
            { getRows() }
        </TableBody>
    )
}

// function TooltipItem({item, id}) {
//     const [tipOpen, setTipOpen] = useState(false)
//     const toggle = () => setTipOpen(!tipOpen)

//     return (
//         <>
//             <span id={'Tooltip-' + id} style={{"cursor":"pointer"}}>...</span>
//             <span>
//                 <Tooltip 
//                     isOpen={tipOpen} 
//                     placement="bottom" 
//                     target={'Tooltip-' + id} 
//                     toggle={toggle}
//                     autohide={false}
//                 >
//                     {item}
//                 </Tooltip>
//             </span>
//         </>
//     )
// }
