import {
    TablePagination,
} from '@mui/material'

export function GenericTableFooter({ data, set_page, set_page_size }) {
    const handlePageChange = (event, newPage) => {
        set_page(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        set_page_size(parseInt(event.target.value, 10))
    }

    return (
        <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={data.count}
            rowsPerPage={data.pageSize}
            page={data.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )
}