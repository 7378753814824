import './Home.css'
import { getData, getDataSet } from './generic/GenericTableHelper'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));
  
export function Home() {
    const [options, setOptions] = useState({ "page": "1", "pageSize": "10", "sortBy": "-avg", "filterBy": { "name": ">count", "value": "25" } });
    let table = { "meta": {}, "data": [], columns:[], "pages": null, "fetched": false, options: options }
    const bounds = null

    let apiUrl = window.location.protocol + "//" + window.location.host + "/api/report_summary/?"

    const dataResults = useQuery({queryKey: ['getHomeSummary', { apiUrl, options }], queryFn: getData })
    if (dataResults.isFetched && dataResults.data ) {
        table.data = dataResults.data
        table.pages = dataResults.data.pages
        table.fetched = true
    } 

    apiUrl = window.location.protocol + "//" + window.location.host + "/api/municipality_code_metrics/?"

    const dataSet = useQuery({queryKey: ['getTopTen', { apiUrl, options, bounds }], queryFn: getData })

    const topten = () => {
        let rows = []

        if (dataSet.isFetched && dataSet.data) {
            const max = dataSet.data.results.length < 10 ? dataSet.data.results.length : 10 
            for ( let i = 0; i < max; i++) {
                let row = dataSet.data.results[i]
                rows.push(
                    <TableRow key={row["name"]}>
                        <TableCell>{row["name"]}, {row["province_name"]}</TableCell>
                        <TableCell align="right">{Math.round(row["avg"])}</TableCell>
                    </TableRow>
                )
            }
        }

        return rows
    }

    return (
        <>
            <Box sx={{
                 flexGrow: 1 ,
                 maxWidth: "800px",
                 p: 2,
                 mx: "auto"
                }}>
                    <Paper elevation={5} sx={{ p: "1em"}}>
                    <Typography variant="body1" >
                            Rental Statistics in Canada is pulling data from various services across Canada. Many thanks 
                            to <Link target="_blank" rel="noreferrer" href="https://kijiji.ca">Kijiji</Link> for 
                            granting permission to pull data from their rental listings. It focuses on Postal Code level granularity for location.
                            In an attempt to eliminate bad data, it filters out data in the top and bottom 1%.
                    </Typography>
                    </Paper>
                    <br />
                    <Paper elevation={20} sx={{ p: "1em"}}>
                    <Typography variant="body1" align='center' >
                       <div>Total number of records: {table.data.records} </div>
                        <div>Total records collected so far today: {table.data.records_today} </div>
                    </Typography>
                    </Paper>
                    <br />
                    <Paper elevation={5} sx={{ p: "1em"}}>
                    <Typography variant="h6" align='center' >
                    <div>Top 10 municipalities by average rent with at least a sample size of 25</div>
                    </Typography>
                       <br />
                       <TableContainer component={Paper}>
                            <Table sx={{  }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Municipality</b></TableCell>
                                        <TableCell align="right"><b>Average Rent ($)</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { topten() }
                                </TableBody>
                            </Table>
                       </TableContainer>
                    </Paper>
           </Box>
        </>
    )
}