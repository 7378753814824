import axios from 'axios';


export const getMetaData = async ({ queryKey }) => {
    const [ _key, { apiUrl } ] = queryKey

    const response = await axios(
        encodeURI(apiUrl),
        {
            method: 'options'
        }
    )

    // console.log(response)
    return response.data
}

export const getData = async ({ queryKey }) => {
    const [ _key, { apiUrl, options, bounds } ] = queryKey

    let url = apiUrl

    if (bounds) {
        url += "bounds=" + JSON.stringify(bounds) + "&"
    }

    if ( options ) {
        if ( options.sortBy !== "") {
            url += "sort_by=" + options.sortBy + "&"
        }
        if ( options.filterBy.name !== "" ) {
            url += "filter_by=" + options.filterBy.name + "&filter_value=" + options.filterBy.value + "&"
        }

        url += "page_size=" + options.pageSize + "&"
        url += "page=" + options.page
    }

    const response = await axios(
        encodeURI(url),
        {
            method: 'get'
        }

    )

    // Set the number of pages returned based on page size. Moved out of state, can be calculated

    if ( options ) {
        response.data.pages = Math.ceil(response.data.count / options.pageSize)
    }

    // console.log(response.data)
    return response.data
}

// export const getDataSet = async ({ queryKey }) => {
//     const [ _key, { dataSetUrl, options, bounds } ] = queryKey

//     let url = dataSetUrl

//     if ( options.sortBy !== "") {
//         url += "sort_by=" + options.sortBy + "&"
//     }

//     if ( options.filterBy.name !== "" ) {
//         url += options.filterBy.name + "=" + options.filterBy.value + "&"
//     }

//     url += "bounds=" + JSON.stringify(bounds) + "&"
//     url += "page_size=" + options.pageSize + "&"
//     url += "page=" + options.page

//     const response = await axios(
//         encodeURI(url),
//         {
//             method: 'get'
//         }

//     )

//     // Set the number of pages returned based on page size. Moved out of state, can be calculated

//     response.data.pages = Math.ceil(response.data.count / options.pageSize)

//     // console.log(response.data)
//     return response.data
// }


export function getAllData(url, options, page) {

}